<template>
  <div>
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
      <div class="container">
        <div class="breadcurmb-title text-center">
          <h2>News Feed</h2>
        </div>
        <div class="breadcurmb-item-list text-center ul-li">
          <ul class="saasio-page-breadcurmb">
            <li><router-link to="/">ACCUEIL</router-link></li>
            <li><a href="javascript:void(0)"> {{ blogTitle }}</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div class="col-md-8">
              <div class="saasio-blog-details-content">
                <div class="blog-details-img">
                  <img src="assets/images/blogs/crm4.PNG" alt="Regelements des client et fournisseurs" />
                </div>
                <div class="blog-details-text dia-headline">
                  <h1>{{ blogTitle }}</h1>
                  <article>
                    La gestion des règlements clients et fournisseurs peut également faire partie des fonctionnalités d'un
                     logiciel CRM. Voici quelques exemples de fonctionnalités que vous pouvez trouver dans un logiciel 
                     CRM pour la gestion des règlements :
                  </article>
                  <h3>Suivi des paiements :</h3>
                  <article>
                    Vous pouvez suivre les paiements des clients et des fournisseurs, identifier les factures en retard et envoyer des rappels de paiement.
                  </article>
                  <h3>Facturation et devis :</h3>
                  <article>
                    Vous pouvez créer des devis et des factures directement dans le logiciel CRM, ajouter des produits ou des services, personnaliser les modèles de devis/facture et envoyer des documents par e-mail.
                  </article>
                  <h3>Gestion des avoirs :</h3>
                  <article>
                    Vous pouvez gérer les avoirs des clients et des fournisseurs, suivre les remboursements et établir des notes de crédit.
                  </article>
                  <h3>Reporting :</h3>
                  <article>
                    Vous pouvez générer des rapports de règlement pour suivre les paiements, les factures en retard, les notes de crédit, etc.
                  </article>
                  <article>
                    En utilisant un logiciel CRM pour la gestion des règlements clients et fournisseurs, vous pouvez centraliser les données de paiement, rationaliser les processus de facturation et de paiement, réduire les erreurs et les retards de paiement, et améliorer la relation avec vos clients et vos fournisseurs. Cela peut également vous permettre de visualiser les données de règlement en un seul endroit, ce qui peut faciliter la prise de décisions et l'analyse de vos résultats.
                  </article>
                </div>
                
                <ShareContent />

              </div>
            </div>

            <RightSideBar />

          </div>
        </div>
      </div>
    </section>
    <!-- End of blog section
        ============================================= -->
  </div>
</template>

<script>
import ShareContent from '../components/ShareContent.vue';
import RightSideBar from '../components/RightSideBar.vue';
export default {
    name : 'BlogDetail5',
    components  : {
      ShareContent,
      RightSideBar
    },
    data() {
      return {
        //blogDetail : require('../assets/blogs/crm9.PNG'),
        blogTitle : "Regelements des client et fournisseurs"
      }
    }
};
</script>

<style>
</style>